import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getLayoutPostSize } from '../../../common/selectors/app-settings-selectors';
import CreatePostButtonMobile from './create-post-button-mobile';
import PageTabs, { ALIGNMENT } from './page-tabs';
import styles from './my-posts-frame-mobile.scss';

const MyPostsFrameMobile = ({ children, layoutPostSize }) => (
  <section
    className={styles.root}
    style={{ '--width': layoutPostSize ? `${layoutPostSize}px` : undefined }}
  >
    <PageTabs alignment={ALIGNMENT.center} />
    <main className={styles.main}>
      <div className={styles.createPostButton}>
        <CreatePostButtonMobile />
      </div>
      {children}
    </main>
  </section>
);

MyPostsFrameMobile.propTypes = {
  layoutPostSize: PropTypes.number.isRequired,
};

const mapRuntimeToProps = (state, _ownProps, _actions, host) => ({
  layoutPostSize: getLayoutPostSize({
    state,
    hostWidth: host.dimensions.width || undefined,
  }),
});

export default connect(mapRuntimeToProps)(MyPostsFrameMobile);
